import { envUtil } from "./index"

export default class Log{
    constructor(namespace = ''){
        this.namespace = namespace
    }
    static logType = {
        log: 'log',
        error: 'error',
        warn: 'warn',
    }
    printInfo(logType, tag, ...args){
        if(envUtil.isProd() && !sessionStorage.getItem('debug')) return//正式线环境中开启debug模式才输入日志
        if(!Log.logType[logType]) throw new Error('log 类型不存在')
        return console[Log.logType[logType]].apply(this, [`%c<${this.namespace}>%c${tag ? `[${tag}]` : ''}`, 'color:#0570ff;', 'color:#e81515;', ...args])
    }
    info(...args){
        this.printInfo(Log.logType.log, null, ...args)
    }
    tag(tag, ...args){
        this.printInfo(Log.logType.log, tag, ...args)
    }
    error(...args){
        this.printInfo(Log.logType.error, null, ...args)
    }
    warn(...args){
        this.printInfo(Log.logType.warn, null, ...args)
    }
}