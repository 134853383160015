<!--
 * @Author: your name
 * @Date: 2020-09-04 10:50:26
 * @LastEditTime: 2022-07-22 17:51:39
 * @LastEditors: Seven
 * @Description: In User Settings Edit
 * @FilePath: \workbench-front\src\views\workbench\order\management\components\remoteShareModal.vue
-->
<template>
  <transition name="el-fade-in">
    <div class="modal-mask" v-show="visible">
      <transition name="modal">
        <div class="modal-wrapper" v-show="visible">
          <div class="modal-wrapper-header">
            <h2>在线收款</h2>
            <i class="iconfont icondelete" @click="onClose">
              <img src="~@/assets/image/关闭.svg"/>
            </i>
          </div>
          <div class="modal-wrapper-post" >
            <div class="post-content" ref="postImg">
              <div class="post-content-body">
                <div  class="goods-img" :style="{backgroundImage: `url(${Formmoney.classImg})`}" alt="" ></div>
                <div class="goods-info">
                   <p class="goods-name">{{Formmoney.commodityName }}</p>
                   <p v-if="Formmoney.businessId == 1" class="rules">{{Formmoney.goodsDetail || ' '}}</p>
                   <h3 v-if="Formmoney.businessId === 0 || Formmoney.businessId === '0'" class="rules" style="margin-left:5px">
                      <span>{{Formmoney.major}}, {{Formmoney.academicLevel}}</span>
                   </h3>
                   <p class="stu-name">
                     <span>报名学员</span>
                     <span style="color:#337AFF">{{Formmoney.stuName}}</span>
                    </p>
                </div>
                <div class="post-content-bottom">
                  <div id="qrcode" ref="qrcode" class="qr-code"></div>
                  <div class="tips">
                    <img  style="margin-bottom:6px" src="@/assets/image/fingerprint.png" alt="">
                    <p>长按识别二维码</p>
                    <p style="margin-top: -2px;">立即收款完成报名</p>
                  </div>
                </div>
              </div>
            
            </div>
            <div class="btn-wrap">
              <el-button style="width:112px;height:36px;" size="medium" @click="onClose">关闭</el-button>
              <el-button style="width:112px;height:35px;margin-left:20px" size="medium" type="primary" @click="getPostImg">下载海报</el-button>
          </div>
          </div>
          
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import QRCode from "qrcodejs2"
import html2canvas from 'html2canvas'
import { formatTimesFileName } from '@/utils/timeDate'
export default {
  name: "RemoteShareModal",
  watch: {
    visible( value ){
      if( value ){
        this.Formmoney.classImg =  this.Formmoney.classImg || ''
        this.Formmoney.classImg =  "/mz_proxy?url="+encodeURI(`${this.Formmoney.classImg}?x-oss-process=image/resize,m_lfit,h_138,w_227`);
      }
    }
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    createCode: {
      type: Object,
      required: {},
    },
    Formmoney: {
      type: Object,
      required: {}
    },
    modalType: {
      type: String,
      default: 'pc'
    },
    createTime: {
      type: String
    }
  },
  data() {
    return {
      qr: null,
    }
  },
  methods: {
    // 生成二维码
    crateQrcode() {

   
   // 测试线: https://mobilepay.beta.hqjy.com
   // 正式线: https://mobilepay.hqjy.com
   // 开发线： https://mobilepay.dev.hqjy.com/
   let commoadcode = this.Formmoney.commodityIdList.toString()
   let params = {
     commodityldListStr: commoadcode,
     tradeOrderId: this.Formmoney.orderId, //this.formorder.orderSetId,
     userId: this.Formmoney.userId
   }

      this.qrcode = `${process.env.VUE_APP_QRCODE}/confirmOrder?commodityldListStr=${params.commodityldListStr}&tradeOrderId=${params.tradeOrderId}&userId=${params.userId}`
     this.qr = new QRCode("qrcode", {
     width:  90,
     height: 90, // 高度
     text: this.qrcode, // 二维码内容
     correctLevel: QRCode.CorrectLevel.L
   })
   this.$refs.qrcode.title = ''//去掉QRCode库设置的title属性
    },


    onClose() {
      this.$refs.qrcode.innerHTML = ""
      this.$emit("onModalClose")
    },
    closeCollection() {
      this.$emit("closeCollection")
    },
    refreshCollection() {
      this.$emit("refreshCollection")
      document.getElementById('qrcode').innerHTML = ''
    },

    /***
     * 生成海报并且下载
     */
    getPostImg() {
      html2canvas(this.$refs.postImg, {
        allowTaint: true,
        taintTest: false,
        useCORS: true,
        scrollY: -1,
        scrollX: -3,
        width: this.$refs.postImg.clientWidth,
        height: this.$refs.postImg.clientHeight,
        scale: window.devicePixelRatio,
      }).then(canvas => {
        // 转成图片，生成图片地址
        let imgUrl = canvas.toDataURL('image/png');
        const eleLink = document.createElement('a');
        eleLink.href = imgUrl; // 转换后的图片地址
        // 文件名 收款海报文件名：商品名-班型名-交款_学员姓名_报名时间
        // eleLink.download = '收款海报'
        eleLink.download = `${this.Formmoney.commodityName}-${this.Formmoney.goodsDetail}-交款_${this.Formmoney.stuName}_${formatTimesFileName(this.createTime)}`
        // 触发点击
        document.body.appendChild(eleLink);
        eleLink.click();
        // 然后移除
        document.body.removeChild(eleLink);
      });
    }
  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .modal-wrapper {
    width: 676px;
    // height: 580px;
    background: #fff;
    border-radius: 6px;
    &-header {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      height: 60px;
      padding-left: 30px;
      border-bottom: 1px solid #e1e4eb;
      > h2 {
        margin-right: 12px;
        font-size: 16px;
        font-weight: 700;
        color: #333;
        > span {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
        }
      }
      > p {
        font-size: 14px;
        color: #999;
      }
      > i {
        position: absolute;
        right: 30px;
        font-size: 18px;
        color: #9bacbf;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
          transform: rotate(180deg);
        }
      }
    }
    &-body {

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 50px 60px 50px 60px;
      border-bottom: 1px solid #E1E4EB;
      &-left {
        font-size: 14px;
        color: #666;
        line-height: 21px;
        margin-right: 60px;
        &>h3 {
          display:inline-block;
          box-sizing: border-box;
          vertical-align: top;
          margin-bottom: 16px;
          &>h3 {
            &>span {
              display:block;
              line-height:20px;
            }
          }
          &>span {
            height: 30px;
            display:block;
          }
          &>span:first-child {
            color:#888;
            line-height: 30px;
          }
          &>span:last-child {
            color: #555;
            line-height: 20px;
          }
          .money {
            color: #F54955 !important;
            font-size: 20px;
            font-weight: bold;
          }
        }
      }



      &-right {
        flex:none;
        width: 230px;
        height: 258px;
        opacity: 0.73;
        background: #E5F2FF;
        position: relative;

        #qrcode {
          background: contain;
          overflow: hidden;
        }
        .qrcode-text {
          margin-top:13px;
          bottom: 10px;
          width: 100%;
          text-align: center;
          font-size: 12px;
          color: #333;
          line-height: 16px;
          letter-spacing: 1px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 5px;
          &>img {
            width: 24px;
            height: 24px;
          }
          &>span {
            font-size: 12px;
            text-align: center;
            color: #777777;
            line-height: 16px;
            letter-spacing: 1px;
            margin-left: 9px;
          }
        }
      }

    }
    .btns {
        display: flex;
        justify-content: center;
        width: 114px;
        height: 36px;
        margin: 18px auto;
        .el-button {
          width: 100%;
        }
    }

    .post-content-header {
      padding: 12px 20px 8px;
      display: flex;
      align-items: center;
      .logo {
          width: 40px;
          height: 40px;
          margin-right: 12px;
          object-fit: contain;
          border: 1px solid #eee;
          border-radius: 20px;
      }
      .title,.space {
        font-size: 14px;
        color: #222;
      }
      .space {
        padding: 0 8px;
      }
      .tip {
        color: #666;
        font-size: 12px;
      }  

    }

    .post-content-body {
      height: 460px;
      position: relative;
      // border: 1px solid red;
      padding: 70px 15px 36px;
      // display: flex;
      // flex-direction: column;
      // align-items: center;

      .goods-img {
          margin: 0 12px;
          width:227px;
          height:138px;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 100%;
          // background-size: cover;
      }
      .goods-info {
        height: 116px;
        margin-bottom:12px;
      }
      .goods-name {
        padding: 5px 12px 0;
        line-height: 1.4;
        margin-bottom:0;
        font-size: 12px;
        color: #333;
        // display: -webkit-box;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 2;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }
      .rules {
        color: #999;
        padding: 4px 12px 10px;
        font-size: 9px;
        line-height: 16px;
        max-height: 7.9em;
        margin-bottom:0;
        // display: -webkit-box;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 2;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }
      .stu-name {
        margin: 0 12px;
        // border: 1px solid #eee;
        height: 30px;
        line-height: 29px;
        text-align: center;
        background-color: #E5F2FF;
         font-size: 12px;
         border-radius: 4px;
         opacity: .6;
        span:first-child {
          color: #666;
          // margin-right: 10px;
        }
        span {
          margin-left: 10px;
          color:#337AFF
        }
       
      }
    }

    .post-content-bottom {
      padding-top: 10px;
      margin-bottom: 20px;
      bottom: 10px;
      display: flex;
      justify-content: center;
       .qr-code {
          width: 90px;
          height: 90px;
          display: flex;
          justify-content: center;
          align-items: flex-start;
     }
     .tips {
          display: flex;
          flex-direction: column;
          position: relative;
          margin-top:2px;
          margin-left: 10px;
          > img {
            width: 38px;
            height: 38px;
            margin: 0 auto;
            margin-bottom: 13px;
          }
          
          p {
            line-height: 20px;
            font-size: 12px;
            color: #666;
            margin-bottom: 0;
          }
      }
    }

   

    &-post {
      padding: 20px 0 0 0;
      .post-content {
        width: 284px;
        height: 504px;
        background: url('~@/assets/image/shoukuanbeijingtu.png') no-repeat;
        background-size:cover;
        margin: 0px auto;
      }
      .refresh-qrcode {
        position: absolute;
        bottom: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        user-select: none;
        cursor: pointer;
        left: 0;
        > img {
          width: 15px;
          height: 15px;
          transition: 0.3s ease;
          &:hover {
            transform: rotate(180deg);
          }
        }
        > span {
          font-size: 12px;
          font-weight: 400;
          color: #777777;
          margin-left: 15px;
        }
      }
      .btn-wrap {
          border: 1px solid #E1E4EB;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 18px 0 20px;
          margin-top: 18px;

      }
    }
  }
}
</style>
