var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "collection" },
    [
      _c(
        "div",
        { staticClass: "form-wrapper" },
        [
          _c(
            "el-form",
            {
              ref: "collectionForm",
              staticClass: "collection-form",
              attrs: {
                inline: true,
                model: _vm.collectionForm,
                rules: _vm.rules,
                "label-width": "100px",
                "label-position": "right"
              }
            },
            [
              _c(
                "div",
                { staticClass: "baseinfo-wrapper page-width" },
                [
                  _c("h2", { staticStyle: { "margin-bottom": "17px" } }, [
                    _vm._v("订单收款")
                  ]),
                  _c("span", [_vm._v("订单信息")]),
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-top": "12px" },
                      attrs: { gutter: 24 }
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              autocomplete: "off",
                              label: "订单编号",
                              "hide-details": "auto",
                              disabled: "",
                              outlined: "",
                              dense: true,
                              height: 32
                            },
                            model: {
                              value: _vm.collectionForm["orderNo"],
                              callback: function($$v) {
                                _vm.$set(_vm.collectionForm, "orderNo", $$v)
                              },
                              expression: "collectionForm['orderNo']"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              autocomplete: "off",
                              label: "报名商品",
                              "hide-details": "auto",
                              disabled: "",
                              outlined: "",
                              dense: true,
                              height: 32
                            },
                            model: {
                              value: _vm.collectionForm["goodsName"],
                              callback: function($$v) {
                                _vm.$set(_vm.collectionForm, "goodsName", $$v)
                              },
                              expression: "collectionForm['goodsName']"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              autocomplete: "off",
                              value:
                                _vm.collectionForm.stuName +
                                "（" +
                                _vm.collectionForm.stuMoblie +
                                "）",
                              label: "学员信息",
                              "hide-details": "auto",
                              disabled: "",
                              outlined: "",
                              dense: true,
                              height: 32
                            }
                          })
                        ],
                        1
                      ),
                      _c("el-col", {
                        staticStyle: { height: "54px" },
                        attrs: { span: 12 }
                      }),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("span", { staticClass: "litter-title" }, [
                          _vm._v("应收金额")
                        ]),
                        _c(
                          "p",
                          {
                            staticStyle: { color: "red", "font-size": "24px" }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.collectionForm.amountReceivable || "0.00"
                              ) + " 元"
                            )
                          ]
                        )
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("span", { staticClass: "litter-title" }, [
                          _vm._v("已收金额")
                        ]),
                        _c(
                          "p",
                          {
                            staticStyle: { color: "red", "font-size": "24px" }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.collectionForm.amountReceived || "0.00"
                              ) + " 元"
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "line" }),
              _c(
                "div",
                { staticClass: "paymode-wrapper page-width" },
                [
                  _c("h2", { staticStyle: { "margin-bottom": "17px" } }, [
                    _vm._v("收款")
                  ]),
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        [
                          _c("span", { staticClass: "litter-title" }, [
                            _vm._v("收款场景")
                          ]),
                          _c("CheckBox", {
                            ref: "checkbox",
                            staticStyle: { margin: "10px 0 10px -10px" },
                            attrs: {
                              list: [
                                { label: "在线收款", value: 1 },
                                { label: "学员已付款", value: 2 }
                              ],
                              select: _vm.collectionForm.type
                            },
                            on: {
                              "update:select": function($event) {
                                return _vm.$set(
                                  _vm.collectionForm,
                                  "type",
                                  $event
                                )
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm.collectionForm.type === 1
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-col",
                                { staticStyle: { "margin-top": "14px" } },
                                [
                                  _c("span", { staticClass: "litter-title" }, [
                                    _vm._v("支付方式")
                                  ]),
                                  _c("CheckBox", {
                                    staticStyle: {
                                      margin: "10px 0 10px -10px"
                                    },
                                    attrs: {
                                      list: _vm.payTypeList,
                                      select: _vm.collectionForm.isReceiveAll
                                    },
                                    on: {
                                      "update:select": function($event) {
                                        return _vm.$set(
                                          _vm.collectionForm,
                                          "isReceiveAll",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _vm.collectionForm.isReceiveAll === 2
                                    ? _c("el-form-item", [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "margin-top": "15px"
                                            }
                                          },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                autocomplete: "off",
                                                label: "首付款(元)",
                                                "hide-details": "auto",
                                                outlined: "",
                                                dense: true,
                                                height: 32,
                                                clearable: true
                                              },
                                              on: {
                                                input: _vm.onFirstPartPayChange,
                                                blur: _vm.endFirstPartPayChange
                                              },
                                              model: {
                                                value:
                                                  _vm.collectionForm
                                                    .preDownPayment,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.collectionForm,
                                                    "preDownPayment",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "collectionForm.preDownPayment"
                                              }
                                            }),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-size": "12px",
                                                  color: "#999"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " 首付款范围: " +
                                                    _vm._s(
                                                      _vm.minFirstPayPrice ||
                                                        0.0
                                                    ) +
                                                    " 元 ~"
                                                )
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-size": "12px",
                                                  color: "#999",
                                                  "margin-left": "5px"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.maxFirstPayPrice || 0.0
                                                  ) + " 元"
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isShowYuE == "ONLINE_YUE",
                                      expression: "isShowYuE == 'ONLINE_YUE'"
                                    }
                                  ],
                                  staticStyle: {
                                    "margin-bottom": "12px",
                                    "margin-top": "14px"
                                  }
                                },
                                [
                                  _c("span", { staticClass: "litter-title" }, [
                                    _vm._v("余额抵扣")
                                  ]),
                                  _c("CheckBox", {
                                    staticStyle: { margin: "10px 0" },
                                    attrs: {
                                      list: [{ label: "是", value: 1 }],
                                      select: _vm.collectionForm.isBalancePay
                                    },
                                    on: {
                                      "update:select": function($event) {
                                        return _vm.$set(
                                          _vm.collectionForm,
                                          "isBalancePay",
                                          $event
                                        )
                                      }
                                    }
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "13px",
                                        color: "#999",
                                        "margin-top": "14px",
                                        display: "block",
                                        "margin-bottom": "6px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "待收金额大于 0 时，可使用余额抵扣"
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm.collectionForm.isBalancePay == 1
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 4 } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              autocomplete: "off",
                                              label: "学习账号",
                                              "hide-details": "auto",
                                              outlined: "",
                                              dense: true,
                                              height: 32,
                                              disabled: "",
                                              clearable: true
                                            },
                                            on: {
                                              blur: function($event) {
                                                return _vm.getBalanceByMoblie(
                                                  _vm.collectionForm
                                                    .balanceAccount
                                                )
                                              }
                                            },
                                            nativeOn: {
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.getBalanceByMoblie(
                                                  _vm.collectionForm.stuMoblie
                                                )
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.collectionForm
                                                  .balanceAccount,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.collectionForm,
                                                  "balanceAccount",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "collectionForm.balanceAccount"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 4 } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              autocomplete: "off",
                                              label: "学员姓名",
                                              "hide-details": "auto",
                                              disabled: "",
                                              outlined: "",
                                              dense: true,
                                              height: 32,
                                              clearable: true
                                            },
                                            model: {
                                              value:
                                                _vm.collectionForm.balanceName,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.collectionForm,
                                                  "balanceName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "collectionForm.balanceName"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 4 } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              autocomplete: "off",
                                              label: "账号余额",
                                              "hide-details": "auto",
                                              disabled: "",
                                              outlined: "",
                                              dense: true,
                                              height: 32,
                                              clearable: true
                                            },
                                            model: {
                                              value:
                                                _vm.collectionForm
                                                  .stuAccountMoney,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.collectionForm,
                                                  "stuAccountMoney",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "collectionForm.stuAccountMoney"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("el-col", {
                                        staticStyle: { height: "48px" },
                                        attrs: { span: 12 }
                                      }),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 4 } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              autocomplete: "off",
                                              value:
                                                _vm.collectionForm.balancePay,
                                              label: "本次使用",
                                              "hide-details": "auto",
                                              outlined: "",
                                              dense: true,
                                              height: 32,
                                              clearable: true
                                            },
                                            on: {
                                              focus: _vm.clearDeafult,
                                              input: _vm.onBalancePayChange
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 20 } },
                                        [
                                          _c("v-text-field", {
                                            staticStyle: {
                                              width: "100px",
                                              display: "inline-block",
                                              "margin-right": "12px"
                                            },
                                            attrs: {
                                              autocomplete: "off",
                                              label: "验证码",
                                              "hide-details": "auto",
                                              outlined: "",
                                              dense: true,
                                              height: 32,
                                              clearable: true
                                            },
                                            model: {
                                              value:
                                                _vm.collectionForm
                                                  .deductionCode,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.collectionForm,
                                                  "deductionCode",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "collectionForm.deductionCode"
                                            }
                                          }),
                                          _c(
                                            "el-button",
                                            {
                                              directives: [
                                                {
                                                  name: "loading",
                                                  rawName: "v-loading",
                                                  value: _vm.smsSendStatus,
                                                  expression: "smsSendStatus"
                                                }
                                              ],
                                              attrs: {
                                                size: "small",
                                                type: "primary"
                                              },
                                              on: { click: _vm.sendSmsCode }
                                            },
                                            [_vm._v(_vm._s(_vm.phoneTipMsg))]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.collectionForm.type === 2
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-col",
                                [
                                  _c("span", { staticClass: "litter-title" }, [
                                    _vm._v("收款凭证")
                                  ]),
                                  _vm._l(_vm.voucherList, function(
                                    item,
                                    index
                                  ) {
                                    return _c(
                                      "el-row",
                                      {
                                        key: index,
                                        staticClass: "voucher-list",
                                        attrs: { gutter: 24 }
                                      },
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "12px"
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "litter-title" },
                                              [_vm._v(_vm._s(item.title))]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px"
                                                },
                                                attrs: { span: 6 }
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      prop: "paymentMethod"
                                                    }
                                                  },
                                                  [
                                                    _c("v-autocomplete", {
                                                      ref: "paymentMethod",
                                                      refInFor: true,
                                                      attrs: {
                                                        items:
                                                          _vm.collectionTypeList,
                                                        "menu-props": {
                                                          bottom: true,
                                                          offsetY: true
                                                        },
                                                        label: "收款方式",
                                                        outlined: "",
                                                        "item-text": "name",
                                                        "item-value": "id",
                                                        dense: true,
                                                        height: 32,
                                                        clearable: true,
                                                        "clear-icon": "$clear",
                                                        "hide-details": "auto",
                                                        "no-data-text":
                                                          "暂无数据"
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.selectPaymentMethod(
                                                            index
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          item.paymentMethod,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "paymentMethod",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.paymentMethod"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            item.paymentMethod == 8
                                              ? _c(
                                                  "el-col",
                                                  { attrs: { span: 6 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      [
                                                        _c("v-autocomplete", {
                                                          attrs: {
                                                            items:
                                                              _vm.backAccountList,
                                                            "menu-props": {
                                                              bottom: true,
                                                              offsetY: true
                                                            },
                                                            label: "银行账户",
                                                            "item-text":
                                                              "accountName",
                                                            "item-value": "id",
                                                            outlined: "",
                                                            dense: true,
                                                            height: 32,
                                                            clearable: true,
                                                            "clear-icon":
                                                              "$clear",
                                                            "hide-details":
                                                              "auto",
                                                            "no-data-text":
                                                              "暂无数据"
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.selectBank
                                                          },
                                                          model: {
                                                            value:
                                                              item.paymentPlatform,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "paymentPlatform",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.paymentPlatform"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            item.paymentMethod == 7
                                              ? _c(
                                                  "el-col",
                                                  { attrs: { span: 6 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      [
                                                        _c("v-autocomplete", {
                                                          attrs: {
                                                            items:
                                                              _vm.loanPlatformList,
                                                            "menu-props": {
                                                              bottom: true,
                                                              offsetY: true
                                                            },
                                                            label: "贷款平台",
                                                            "item-text":
                                                              "loanPlatformName",
                                                            "item-value": "id",
                                                            outlined: "",
                                                            dense: true,
                                                            height: 32,
                                                            clearable: true,
                                                            "clear-icon":
                                                              "$clear",
                                                            "hide-details":
                                                              "auto",
                                                            "no-data-text":
                                                              "暂无数据"
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.selectpayment
                                                          },
                                                          model: {
                                                            value:
                                                              item.paymentPlatform,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "paymentPlatform",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.paymentPlatform"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        _c("el-col", { attrs: { span: 7 } }, [
                                          _c(
                                            "div",
                                            { staticClass: "upload-box" },
                                            [
                                              !item.scaleImgUrl
                                                ? _c(
                                                    "el-upload",
                                                    {
                                                      staticClass:
                                                        "avatar-uploader",
                                                      attrs: {
                                                        action:
                                                          _vm.imgUpload.host,
                                                        "show-file-list": false,
                                                        "on-success": _vm.handleAvatarSuccess(
                                                          index
                                                        ),
                                                        data: _vm.imgUpload,
                                                        "before-upload": _vm.beforeAvatarUpload(
                                                          index
                                                        ),
                                                        "auto-upload": "",
                                                        "http-request": _vm.uploadRequest(
                                                          index
                                                        )
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-plus avatar-uploader-icon"
                                                      })
                                                    ]
                                                  )
                                                : _vm._e(),
                                              item.scaleImgUrl
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        position: "relative"
                                                      }
                                                    },
                                                    [
                                                      _c("el-image", {
                                                        staticClass: "avatar",
                                                        staticStyle: {
                                                          "object-fit":
                                                            "contain"
                                                        },
                                                        attrs: {
                                                          src: item.scaleImgUrl,
                                                          "preview-src-list": [
                                                            item.scaleImgUrl
                                                          ]
                                                        }
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            fontSize: "20px",
                                                            color: "#fff",
                                                            "line-height":
                                                              "26px",
                                                            textAlign: "center",
                                                            position:
                                                              "absolute",
                                                            "border-radius":
                                                              "13px",
                                                            width: "26px",
                                                            height: "26px",
                                                            right: "-10px",
                                                            top: "-10px",
                                                            backgroundColor:
                                                              "red"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.removePic(
                                                                index
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("x")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ]),
                                        _c(
                                          "el-col",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "12px"
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "litter-title" },
                                              [_vm._v("凭证信息")]
                                            )
                                          ]
                                        ),
                                        item.paymentMethod == 8
                                          ? _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c("div", [
                                                  _vm._v("流水号"),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "red"
                                                      }
                                                    },
                                                    [_vm._v("*")]
                                                  )
                                                ]),
                                                _c(
                                                  "el-row",
                                                  [
                                                    [
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 24 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              staticStyle: {
                                                                "margin-bottom":
                                                                  "0",
                                                                width: "100%"
                                                              }
                                                            },
                                                            [
                                                              _vm.isBank &&
                                                              _vm.selectNcVoucher ==
                                                                1
                                                                ? _c(
                                                                    "v-autocomplete",
                                                                    {
                                                                      staticStyle: {
                                                                        width:
                                                                          "120px",
                                                                        display:
                                                                          "inline-block"
                                                                      },
                                                                      attrs: {
                                                                        items: [
                                                                          {
                                                                            name:
                                                                              "搜索选择",
                                                                            id: true
                                                                          },
                                                                          {
                                                                            name:
                                                                              "手动输入",
                                                                            id: false
                                                                          }
                                                                        ],
                                                                        "menu-props": {
                                                                          bottom: true,
                                                                          offsetY: true
                                                                        },
                                                                        "item-text":
                                                                          "name",
                                                                        "item-value":
                                                                          "id",
                                                                        outlined:
                                                                          "",
                                                                        dense: true,
                                                                        height: 32,
                                                                        "clear-icon":
                                                                          "$clear",
                                                                        "hide-details":
                                                                          "auto",
                                                                        "no-data-text":
                                                                          "暂无数据"
                                                                      },
                                                                      on: {
                                                                        change: function(
                                                                          $event
                                                                        ) {
                                                                          item.serialNumber =
                                                                            ""
                                                                        }
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.isSearch,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.isSearch = $$v
                                                                        },
                                                                        expression:
                                                                          "isSearch"
                                                                      }
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  ref:
                                                                    "serialNumber",
                                                                  refInFor: true,
                                                                  staticStyle: {
                                                                    display:
                                                                      "inline-block",
                                                                    width:
                                                                      "400px"
                                                                  },
                                                                  attrs: {
                                                                    autocomplete:
                                                                      "off",
                                                                    "hide-details":
                                                                      "auto",
                                                                    outlined:
                                                                      "",
                                                                    readonly:
                                                                      _vm.isSearch &&
                                                                      _vm.isBank &&
                                                                      _vm.selectNcVoucher ==
                                                                        1,
                                                                    dense: true,
                                                                    height: 32,
                                                                    placeholder:
                                                                      "银行流水"
                                                                  },
                                                                  on: {
                                                                    blur: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.validateSerialNumber(
                                                                        index
                                                                      )
                                                                    }
                                                                  },
                                                                  nativeOn: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.validateThird(
                                                                        item.paymentMethod,
                                                                        index
                                                                      )
                                                                    }
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      item.serialNumber,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        item,
                                                                        "serialNumber",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "item.serialNumber"
                                                                  }
                                                                },
                                                                [
                                                                  _vm.isSearch &&
                                                                  _vm.isBank &&
                                                                  _vm.selectNcVoucher ==
                                                                    1
                                                                    ? _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            slot:
                                                                              "append",
                                                                            color:
                                                                              "red"
                                                                          },
                                                                          slot:
                                                                            "append"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " search "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ],
                                                                1
                                                              ),
                                                              _vm.isBank &&
                                                              item.serialNumber &&
                                                              _vm.isSearch
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle: {
                                                                        color:
                                                                          "red",
                                                                        "font-size":
                                                                          "12px",
                                                                        "line-height":
                                                                          "20px"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "订单可用金额" +
                                                                          _vm._s(
                                                                            _vm.availablemoney
                                                                          ) +
                                                                          "元"
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    _c(
                                                      "el-col",
                                                      {
                                                        staticStyle: {
                                                          "margin-top": "12px"
                                                        },
                                                        attrs: { span: 6 }
                                                      },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          [
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                autocomplete:
                                                                  "off",
                                                                label:
                                                                  "收款金额",
                                                                "hide-details":
                                                                  "auto",
                                                                outlined: "",
                                                                dense: true,
                                                                height: 32,
                                                                clearable: true
                                                              },
                                                              on: {
                                                                input: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.changeCollectionTotalMoney(
                                                                    index
                                                                  )
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  item.money,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "money",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "item.money"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  2
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.payType == 5
                                          ? _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "el-row",
                                                  [
                                                    _vm.payType == 5 &&
                                                    (_vm.selectNcVoucher != 0 ||
                                                      _vm.relateTyStatement !=
                                                        0)
                                                      ? _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 6 }
                                                          },
                                                          [
                                                            _c(
                                                              "el-form-item",
                                                              {
                                                                staticStyle: {
                                                                  "margin-bottom":
                                                                    "0"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-text-field",
                                                                  {
                                                                    ref:
                                                                      "serialNumber",
                                                                    refInFor: true,
                                                                    attrs: {
                                                                      autocomplete:
                                                                        "off",
                                                                      label:
                                                                        "第三方订单号",
                                                                      "hide-details":
                                                                        "auto",
                                                                      outlined:
                                                                        "",
                                                                      readonly:
                                                                        "",
                                                                      dense: true,
                                                                      height: 32
                                                                    },
                                                                    nativeOn: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.validateThird(
                                                                          item.paymentMethod,
                                                                          index
                                                                        )
                                                                      }
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item.serialNumber,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "serialNumber",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "item.serialNumber"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          slot:
                                                                            "append",
                                                                          color:
                                                                            "red"
                                                                        },
                                                                        slot:
                                                                          "append"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " search "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm.payType ==
                                                                  5 &&
                                                                (_vm.selectNcVoucher !=
                                                                  0 ||
                                                                  _vm.relateTyStatement !=
                                                                    0) &&
                                                                item.serialNumber
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticStyle: {
                                                                          color:
                                                                            "red",
                                                                          "font-size":
                                                                            "12px",
                                                                          "line-height":
                                                                            "20px"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "第三方订单可用金额" +
                                                                            _vm._s(
                                                                              _vm.availablemoney
                                                                            ) +
                                                                            "元"
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.selectNcVoucher == 0 &&
                                                    _vm.relateTyStatement == 0
                                                      ? _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 6 }
                                                          },
                                                          [
                                                            _c(
                                                              "el-form-item",
                                                              [
                                                                _c(
                                                                  "v-text-field",
                                                                  {
                                                                    ref:
                                                                      "serialNumber",
                                                                    refInFor: true,
                                                                    attrs: {
                                                                      autocomplete:
                                                                        "off",
                                                                      label:
                                                                        "第三方订单号",
                                                                      "hide-details":
                                                                        "auto",
                                                                      outlined:
                                                                        "",
                                                                      dense: true,
                                                                      height: 32,
                                                                      clearable: true
                                                                    },
                                                                    on: {
                                                                      blur: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.validateSerialNumber(
                                                                          index
                                                                        )
                                                                      }
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item.serialNumber,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "serialNumber",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "item.serialNumber"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 6 } },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          [
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                autocomplete:
                                                                  "off",
                                                                label:
                                                                  "收款金额",
                                                                "hide-details":
                                                                  "auto",
                                                                outlined: "",
                                                                dense: true,
                                                                height: 32,
                                                                clearable: true
                                                              },
                                                              on: {
                                                                input: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.changeCollectionTotalMoney(
                                                                    index
                                                                  )
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  item.money,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "money",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "item.money"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        item.paymentMethod == 7
                                          ? _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "el-row",
                                                  [
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 24 } },
                                                      [
                                                        _c(
                                                          "el-row",
                                                          {
                                                            attrs: {
                                                              gutter: 12
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "el-col",
                                                              {
                                                                attrs: {
                                                                  span: 6
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-text-field",
                                                                  {
                                                                    attrs: {
                                                                      autocomplete:
                                                                        "off",
                                                                      label:
                                                                        "贷款金额(元)",
                                                                      "hide-details":
                                                                        "auto",
                                                                      outlined:
                                                                        "",
                                                                      dense: true,
                                                                      height: 32,
                                                                      clearable: true
                                                                    },
                                                                    on: {
                                                                      input: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.inputLoanAmount(
                                                                          index
                                                                        )
                                                                      }
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item.money,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "money",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "item.money"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "el-col",
                                                              {
                                                                attrs: {
                                                                  span: 3
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-text-field",
                                                                  {
                                                                    attrs: {
                                                                      autocomplete:
                                                                        "off",
                                                                      label:
                                                                        "期数",
                                                                      "hide-details":
                                                                        "auto",
                                                                      outlined:
                                                                        "",
                                                                      dense: true,
                                                                      height: 32,
                                                                      clearable: true
                                                                    },
                                                                    on: {
                                                                      input: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.inputInstallmentNumber(
                                                                          index
                                                                        )
                                                                      }
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item.installmentNumber,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "installmentNumber",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "item.installmentNumber"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "el-col",
                                                              {
                                                                attrs: {
                                                                  span: 4
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-text-field",
                                                                  {
                                                                    attrs: {
                                                                      autocomplete:
                                                                        "off",
                                                                      label:
                                                                        "贴息率(%)",
                                                                      "hide-details":
                                                                        "auto",
                                                                      outlined:
                                                                        "",
                                                                      dense: true,
                                                                      height: 32,
                                                                      clearable: true
                                                                    },
                                                                    on: {
                                                                      input: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.inputCompanyDiscountRate(
                                                                          index
                                                                        )
                                                                      }
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item.companyDiscountRate,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "companyDiscountRate",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "item.companyDiscountRate"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "el-col",
                                                              {
                                                                attrs: {
                                                                  span: 4
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-text-field",
                                                                  {
                                                                    attrs: {
                                                                      autocomplete:
                                                                        "off",
                                                                      label:
                                                                        "贴息(元)",
                                                                      "hide-details":
                                                                        "auto",
                                                                      outlined:
                                                                        "",
                                                                      dense: true,
                                                                      height: 32,
                                                                      clearable: true
                                                                    },
                                                                    on: {
                                                                      input: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.inputCompanyDiscountMoney(
                                                                          index
                                                                        )
                                                                      }
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item.companyDiscountMoney,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "companyDiscountMoney",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "item.companyDiscountMoney"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "el-col",
                                                              {
                                                                attrs: {
                                                                  span: 4
                                                                }
                                                              },
                                                              [
                                                                _vm.paymentName ==
                                                                  "芝士白条" &&
                                                                _vm.property ==
                                                                  6
                                                                  ? _c(
                                                                      "v-text-field",
                                                                      {
                                                                        attrs: {
                                                                          autocomplete:
                                                                            "off",
                                                                          label:
                                                                            "保证金（元）",
                                                                          "hide-details":
                                                                            "auto",
                                                                          outlined:
                                                                            "",
                                                                          dense: true,
                                                                          height: 32,
                                                                          clearable: true
                                                                        },
                                                                        on: {
                                                                          input: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.inputCompanyguaranteeMoney(
                                                                              index
                                                                            )
                                                                          }
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            item.guaranteeMoney,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              item,
                                                                              "guaranteeMoney",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "item.guaranteeMoney"
                                                                        }
                                                                      }
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "el-row",
                                                          {
                                                            attrs: {
                                                              gutter: 12
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "el-col",
                                                              {
                                                                attrs: {
                                                                  span: 6
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-text-field",
                                                                  {
                                                                    attrs: {
                                                                      autocomplete:
                                                                        "off",
                                                                      label:
                                                                        "到账金额(元)",
                                                                      "hide-details":
                                                                        "auto",
                                                                      outlined:
                                                                        "",
                                                                      dense: true,
                                                                      height: 32,
                                                                      clearable: true
                                                                    },
                                                                    on: {
                                                                      input: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.inputLoanReceivedMoney(
                                                                          index
                                                                        )
                                                                      }
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item.loanReceivedMoney,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "loanReceivedMoney",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "item.loanReceivedMoney"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "el-col",
                                                              {
                                                                attrs: {
                                                                  span: 6
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-menu",
                                                                  {
                                                                    ref: "menu",
                                                                    refInFor: true,
                                                                    attrs: {
                                                                      "close-on-content-click": false,
                                                                      "return-value":
                                                                        item.loanTime,
                                                                      transition:
                                                                        "scale-transition",
                                                                      "offset-y":
                                                                        "",
                                                                      "min-width":
                                                                        "auto"
                                                                    },
                                                                    on: {
                                                                      "update:returnValue": function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          item,
                                                                          "loanTime",
                                                                          $event
                                                                        )
                                                                      },
                                                                      "update:return-value": function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          item,
                                                                          "loanTime",
                                                                          $event
                                                                        )
                                                                      }
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "activator",
                                                                          fn: function(
                                                                            ref
                                                                          ) {
                                                                            var on =
                                                                              ref.on
                                                                            var attrs =
                                                                              ref.attrs
                                                                            return [
                                                                              _c(
                                                                                "v-text-field",
                                                                                _vm._g(
                                                                                  _vm._b(
                                                                                    {
                                                                                      attrs: {
                                                                                        autocomplete:
                                                                                          "off",
                                                                                        label:
                                                                                          "放款日期",
                                                                                        "hide-details":
                                                                                          "auto",
                                                                                        outlined:
                                                                                          "",
                                                                                        dense: true,
                                                                                        height: 32,
                                                                                        clearable: true
                                                                                      },
                                                                                      model: {
                                                                                        value:
                                                                                          item.loanTime,
                                                                                        callback: function(
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            item,
                                                                                            "loanTime",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                        expression:
                                                                                          "item.loanTime"
                                                                                      }
                                                                                    },
                                                                                    "v-text-field",
                                                                                    attrs,
                                                                                    false
                                                                                  ),
                                                                                  on
                                                                                )
                                                                              )
                                                                            ]
                                                                          }
                                                                        }
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                    model: {
                                                                      value:
                                                                        _vm.menu,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.menu = $$v
                                                                      },
                                                                      expression:
                                                                        "menu"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-date-picker",
                                                                      {
                                                                        attrs: {
                                                                          "no-title":
                                                                            "",
                                                                          locale:
                                                                            "zh-cn"
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            item.loanTime,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              item,
                                                                              "loanTime",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "item.loanTime"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-spacer"
                                                                        ),
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            attrs: {
                                                                              text:
                                                                                "",
                                                                              color:
                                                                                "primary"
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                _vm.menu = false
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "取消"
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            attrs: {
                                                                              text:
                                                                                "",
                                                                              color:
                                                                                "primary"
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.$refs.menu[0].save(
                                                                                  item.loanTime
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " 确认 "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "el-col",
                                                              {
                                                                attrs: {
                                                                  span: 6
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-text-field",
                                                                  {
                                                                    attrs: {
                                                                      autocomplete:
                                                                        "off",
                                                                      label:
                                                                        "借据号",
                                                                      "hide-details":
                                                                        "auto",
                                                                      outlined:
                                                                        "",
                                                                      dense: true,
                                                                      height: 32,
                                                                      clearable: true
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item.serialNumber,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "serialNumber",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "item.serialNumber"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        item.paymentMethod != 7 &&
                                        item.paymentMethod != 8 &&
                                        _vm.payType != 5
                                          ? _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "el-row",
                                                  [
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 6 } },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          [
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                autocomplete:
                                                                  "off",
                                                                label:
                                                                  "特批单号",
                                                                "hide-details":
                                                                  "auto",
                                                                outlined: "",
                                                                dense: true,
                                                                height: 32
                                                              },
                                                              model: {
                                                                value:
                                                                  item.serialNumber,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "serialNumber",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "item.serialNumber"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 6 } },
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          [
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                autocomplete:
                                                                  "off",
                                                                label:
                                                                  "收款金额",
                                                                "hide-details":
                                                                  "auto",
                                                                outlined: "",
                                                                dense: true,
                                                                height: 32,
                                                                clearable: true
                                                              },
                                                              on: {
                                                                input: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.changeCollectionTotalMoney(
                                                                    index
                                                                  )
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  item.money,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "money",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "item.money"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "el-col",
                                          {
                                            staticStyle: {
                                              "margin-top": "10px"
                                            },
                                            attrs: { span: 12 }
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              { staticClass: "memo-form" },
                                              [
                                                _c("v-textarea", {
                                                  attrs: {
                                                    outlined: "",
                                                    height: 80,
                                                    name: "input-7-4",
                                                    label: "凭证备注",
                                                    value: "",
                                                    placeholder:
                                                      "请输入，最多500字"
                                                  },
                                                  model: {
                                                    value: item.remark,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "remark",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.remark"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "deleteBtn",
                                            attrs: { type: "text" },
                                            on: {
                                              click: function($event) {
                                                return _vm.delClick(index)
                                              }
                                            }
                                          },
                                          [_vm._v("删除")]
                                        )
                                      ],
                                      1
                                    )
                                  })
                                ],
                                2
                              ),
                              _c(
                                "el-col",
                                [
                                  !_vm.voucherList.length
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            disabled: _vm.waitPay <= 0,
                                            size: "small",
                                            type: "primary"
                                          },
                                          on: { click: _vm.addClick }
                                        },
                                        [_vm._v("添加凭证")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("el-col", { staticStyle: { "margin-top": "16px" } }, [
                        _c("span", { staticClass: "litter-title" }, [
                          _vm._v("待收金额")
                        ]),
                        _c(
                          "p",
                          {
                            staticStyle: { color: "red", "font-size": "24px" }
                          },
                          [_vm._v(_vm._s(_vm.waitPay) + " 元")]
                        )
                      ]),
                      _vm.collectionForm.isReceiveAll === 2 &&
                      _vm.collectionForm.type === 1
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          autocomplete: "off",
                                          label: "首付款待付(元)",
                                          "hide-details": "auto",
                                          outlined: "",
                                          readonly: "",
                                          dense: true,
                                          height: 32
                                        },
                                        model: {
                                          value: _vm.needPay,
                                          callback: function($$v) {
                                            _vm.needPay = $$v
                                          },
                                          expression: "needPay"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          autocomplete: "off",
                                          label: "贷款金额(元)",
                                          "hide-details": "auto",
                                          outlined: "",
                                          readonly: "",
                                          dense: true,
                                          height: 32
                                        },
                                        model: {
                                          value: _vm.loadPay,
                                          callback: function($$v) {
                                            _vm.loadPay = $$v
                                          },
                                          expression: "loadPay"
                                        }
                                      }),
                                      _vm.isAllowLoan
                                        ? _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                color: "red",
                                                fontSize: "12px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "当前贷款金额低于最低贷款金额,无法贷款"
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.collectionForm.type == 2
                        ? _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  items: _vm.cashierCheckList,
                                  "menu-props": { bottom: true, offsetY: true },
                                  label: "出纳审核",
                                  "item-text": "userName",
                                  "item-value": "userId",
                                  outlined: "",
                                  dense: true,
                                  height: 32,
                                  clearable: true,
                                  "clear-icon": "$clear",
                                  "hide-details": "auto",
                                  "no-data-text": "暂无数据"
                                },
                                model: {
                                  value: _vm.dealQwUserId,
                                  callback: function($$v) {
                                    _vm.dealQwUserId = $$v
                                  },
                                  expression: "dealQwUserId"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "line" }),
              _c(
                "div",
                { staticClass: "bottom-btn-wrapper" },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { size: "middle", gap: 50 },
                      on: { click: _vm.goOrderManagement }
                    },
                    [_vm._v("查看订单")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "120px" },
                      attrs: {
                        disabled: _vm.disableSubmit,
                        size: "middle",
                        type: "primary",
                        loading: _vm.loading
                      },
                      on: {
                        click: function($event) {
                          return _vm.onSubmit(_vm.collectionForm)
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.collectionForm.type != 2 ? "收款" : "提交审核"
                        )
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c("remote-share-modal", {
        ref: "mychild",
        attrs: {
          visible: _vm.rsModalVisible,
          createCode: _vm.collectionForm,
          Formmoney: _vm.Formmoney,
          createTime: _vm.orderDetails.createTime
        },
        on: {
          onModalClose: _vm.onRsModalClose,
          closeCollection: _vm.closeCollection,
          refreshCollection: _vm.refreshCollection
        }
      }),
      _c("third-party", { ref: "third", on: { thirdSelect: _vm.thirdSelect } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }